import React from "react"
import Layout from "../../components/layout-materials"
import { Link } from "gatsby"

export default () => (
  <Layout title="Piano Teaching Product">
    <h2>Piano Teaching Products</h2>
    <div className="clear-block">
      <div id="node-28" className="node">
        <div className="content clear-block">
          <p><img src="/images/001-elle-300x225.jpg" className="float-right" width="300"
                  height="225" alt=""/></p>
          <p>Books for Piano – following are the books created by Noteimals, using the Animal Note method, for beginning
            piano students.</p>
          <p>“<Link to="/books/Beginning-Note-Reading">Note Reading for Children</Link>” is the starting point for this
            method. It is not only good as the beginner book for almost all beginners, it will help an individual who
            has quit or wants to quit because note reading is just too difficult. A 3 year old and some 4 year olds will
            take a year to complete it. Most older children and adults will move more quickly through the book, but
            remember, as their teacher, you want to give each student, regardless of their age, a strong foundation that
            will not result in frustrations later.</p>
          <p>
            <center>Sample Page<br/><img
              src="/images/Stormi%27s%20Dog%20Song.preview-400x283.jpg"
              className="float-none" width="400" height="283" alt="Song preview"/></center>
          </p>
          <p>“<Link to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size">Animal Note/standard note
            Flash Card Set</Link>" has a card for each note found on the Grand Staff. The front of each card has the Animal
            Note in its correct location on the staff with the corresponding standard note on the reverse side. A list
            of suggested word clues for each note is included with the card. These should be studied frequently. Repeat
            the appropriate word clue for each note while viewing first the Animal Note and then its standard note.
            (Sometimes the child likes to call the standard note the back side of the Animal Note. It’s fun for them and
            sometimes helpful in their learning and understanding.) </p>
          <p>“<Link to="/books/Beginning-Timing">Timing for Children</Link>” is a study in counting to learn the correct
            proportions between the notes and to develop the ability to hear these differences when they are seen and
            played in a piece of music. It is so important to play these proportions correctly. This is as much a part
            of building a strong foundation in music skills as note reading. Each note played must be identified on the
            Grand Staff and held for the correct time when playing any piece of music.</p>
          <p>"<Link to="/books/The-Fun-Song-Book">Fun Book–One</Link>” contains simple arrangements of songs familiar to
            most students. It allows applying their newly learned skills of note reading and timing. A couple of new
            notes are introduced, along with sharps and flats. It is always enjoyable to play a song that the student
            recognizes and that their listening audience recognizes. This book can be used in conjunction with the
            “Timing Book.”</p>
          <p>“<Link to="/books/More-Fun-Songs">Fun Book–Two</Link>” adds more harmony in the Bass Clef and new notes. This
            book will give your student more time to build their skills before starting the “Moving On” book series. You
            will find it beneficial to give your student an opportunity to play more familiar songs while they are
            learning the basics as it does encourage them to practice and learn. </p>
          <p>“<Link to="/books/Christmas-Song-Book">Christmas Song Book</Link>” Like the “Fun Song Books,” this book gives
            the students a chance to play some songs familiar to them and others. However this book is based on a
            Seasonal Theme.</p>
          <p>"<Link to="/books/Moving-On-Book-One">Moving On-One</Link>” This book offers simple songs that are three lines
            long. The song is first presented in the Animal Notes, then in standard music notation. This, plus continued
            review with the flash cards and word clues, will make a smooth transition to standard music books.</p>
          <p>“<Link to="/books/Theory-Book">Basic Elements of Theory</Link>” contains some of the important and fundamental
            elements of music theory. It uses associative learning, thus providing a different way to introduce these
            important basics of music in a way a child can more easily understand. It is not a workbook, but a reference
            book, to be used when teaching and a place to review these valuable facts later.</p>
          <h3>Testimonial</h3>
          <p><i>"Thank you! I love this cute and unique approach. I teach Music Together classes, and my daughter (now
            3.5) has been watching (and "helping!") me play her whole life, and lately has been begging me to teach her.
            This seems like an excellent way to begin more "formal" studies in a fun way."</i><br/>
            - Shara </p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>

  </Layout>
)
